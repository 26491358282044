import { Locale } from "../schema/Locale.js";

const numberFormats: Record<Locale, { fraction: Intl.NumberFormat; noFraction: Intl.NumberFormat }> = {
	[Locale.cs]: {
		fraction: new Intl.NumberFormat("cs-CZ", {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}),
		noFraction: new Intl.NumberFormat("cs-CZ", {
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
		}),
	},
};

export function formatNumber(number: number | string, locale: Locale, withFraction: boolean): string {
	return numberFormats[locale][withFraction ? "fraction" : "noFraction"].format(
		typeof number === "string" ? parseFloat(number) : number,
	);
}
