import type { OrderProduct } from "../OrderProduct.js";
import type { ProductForCustomer } from "../Product.js";
import { doesProductForCustomerHaveMultipleSizes } from "./doesProductForCustomerHaveMultipleSizes.js";

export function shouldShowProductForCustomerSize(
	product: Pick<ProductForCustomer, "priceForCustomer" | "sizeLabel">,
	orderProduct?: OrderProduct,
): boolean {
	return (
		doesProductForCustomerHaveMultipleSizes(product) ||
		(orderProduct ? orderProduct.product.sizeLabel : product.sizeLabel) !== undefined
	);
}
